exports.components = {
  "component---src-modules-imprint-tsx": () => import("./../../../src/modules/imprint.tsx" /* webpackChunkName: "component---src-modules-imprint-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campaigns-basic-needs-1-tsx": () => import("./../../../src/pages/campaigns/basic-needs-1.tsx" /* webpackChunkName: "component---src-pages-campaigns-basic-needs-1-tsx" */),
  "component---src-pages-campaigns-civilian-2023-tsx": () => import("./../../../src/pages/campaigns/civilian-2023.tsx" /* webpackChunkName: "component---src-pages-campaigns-civilian-2023-tsx" */),
  "component---src-pages-campaigns-civilian-help-1-tsx": () => import("./../../../src/pages/campaigns/civilian-help-1.tsx" /* webpackChunkName: "component---src-pages-campaigns-civilian-help-1-tsx" */),
  "component---src-pages-campaigns-donation-ambulance-tsx": () => import("./../../../src/pages/campaigns/donation-ambulance.tsx" /* webpackChunkName: "component---src-pages-campaigns-donation-ambulance-tsx" */),
  "component---src-pages-campaigns-donation-batteries-tsx": () => import("./../../../src/pages/campaigns/donation-batteries.tsx" /* webpackChunkName: "component---src-pages-campaigns-donation-batteries-tsx" */),
  "component---src-pages-campaigns-donation-mukachevo-tsx": () => import("./../../../src/pages/campaigns/donation-mukachevo.tsx" /* webpackChunkName: "component---src-pages-campaigns-donation-mukachevo-tsx" */),
  "component---src-pages-campaigns-soldiers-2023-tsx": () => import("./../../../src/pages/campaigns/soldiers-2023.tsx" /* webpackChunkName: "component---src-pages-campaigns-soldiers-2023-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

